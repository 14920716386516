import React from 'react';

const OutsourcingHeadhunting = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9792 19.1646C11.2524 19.1579 9.5983 18.469 8.37726 17.248C7.15623 16.027 6.46737 14.3729 6.46069 12.6461V7.37007C6.46069 5.64122 7.14735 3.98319 8.36983 2.7607C9.59232 1.53822 11.2504 0.851562 12.9792 0.851562C14.7081 0.851562 16.3661 1.53822 17.5886 2.7607C18.8111 3.98319 19.498 5.64122 19.498 7.37007V12.6461C19.4913 14.3729 18.8022 16.027 17.5811 17.248C16.3601 18.469 14.706 19.1579 12.9792 19.1646ZM12.9792 2.11978C11.5867 2.11978 10.2512 2.67304 9.2665 3.65768C8.28186 4.64233 7.72891 5.97757 7.72891 7.37007V12.6461C7.72891 14.0386 8.28186 15.3738 9.2665 16.3585C10.2512 17.3431 11.5867 17.8964 12.9792 17.8964C14.3717 17.8964 15.7073 17.3431 16.6919 16.3585C17.6765 15.3738 18.2298 14.0386 18.2298 12.6461V7.37007C18.2298 6.68058 18.0939 5.99793 17.8301 5.36092C17.5662 4.72391 17.1794 4.14523 16.6919 3.65768C16.2044 3.17014 15.6254 2.78336 14.9884 2.51951C14.3513 2.25565 13.6687 2.11978 12.9792 2.11978Z"
      fill="#475467"
    />
    <path
      d="M20.2324 35.7252H5.41965C4.193 35.7185 3.01874 35.2264 2.15374 34.3567C1.28873 33.4869 0.803448 32.3103 0.803467 31.0837V25.6556C0.866887 25.0268 1.07337 24.4207 1.40754 23.8843C1.74172 23.3478 2.19441 22.8951 2.73087 22.561C3.84057 21.7057 5.10762 21.0766 6.45967 20.7094L11.0505 19.4158C11.1304 19.3925 11.2141 19.3854 11.2969 19.3944C11.3797 19.4035 11.4598 19.4287 11.5329 19.4687C11.6803 19.5496 11.7898 19.6856 11.8369 19.8471C11.8639 19.927 11.874 20.0114 11.8666 20.0954C11.8593 20.1794 11.8344 20.2611 11.7939 20.3351C11.7533 20.409 11.6979 20.4735 11.631 20.5249C11.5642 20.5762 11.4875 20.6131 11.4056 20.6332L6.81481 21.9268C5.62084 22.249 4.5008 22.8004 3.51732 23.5502C2.85785 24.0828 2.07169 24.8693 2.07169 25.6556V31.0837C2.07166 31.974 2.42354 32.8281 3.05072 33.46C3.67789 34.0919 4.52935 34.4503 5.41965 34.457H20.2324C20.4005 34.457 20.5618 34.5239 20.6807 34.6428C20.7996 34.7617 20.8665 34.9229 20.8665 35.0911C20.8665 35.2593 20.7996 35.4205 20.6807 35.5394C20.5618 35.6584 20.4005 35.7252 20.2324 35.7252Z"
      fill="#475467"
    />
    <path
      d="M17.5667 21.5488H17.3889L14.3961 20.7116C14.2337 20.6641 14.0956 20.5561 14.0106 20.4097C13.9257 20.2633 13.9001 20.0901 13.9394 19.9255C13.9865 19.764 14.0957 19.6277 14.2432 19.5468C14.3906 19.4659 14.5644 19.4471 14.7259 19.4941L17.7441 20.3311C17.8906 20.3738 18.0168 20.4681 18.0995 20.5964C18.1822 20.7247 18.2159 20.8785 18.1943 21.0296C18.1727 21.1807 18.0975 21.3191 17.9822 21.4191C17.8669 21.5191 17.7193 21.5742 17.5667 21.5742V21.5488Z"
      fill="#475467"
    />
    <path
      d="M11.1278 20.6592C10.9596 20.6592 10.7983 20.5926 10.6794 20.4737C10.5605 20.3548 10.4937 20.1932 10.4937 20.0251V18.5286C10.4937 18.3605 10.5605 18.1992 10.6794 18.0803C10.7983 17.9614 10.9596 17.8945 11.1278 17.8945C11.2959 17.8945 11.4572 17.9614 11.5761 18.0803C11.695 18.1992 11.7619 18.3605 11.7619 18.5286V20.0251C11.7619 20.1932 11.695 20.3548 11.5761 20.4737C11.4572 20.5926 11.2959 20.6592 11.1278 20.6592Z"
      fill="#475467"
    />
    <path
      d="M14.5555 20.7099C14.3893 20.7037 14.2319 20.6349 14.1143 20.5174C13.9967 20.3998 13.9277 20.242 13.9214 20.0758V18.5286C13.9214 18.3605 13.9882 18.1992 14.1072 18.0803C14.2261 17.9614 14.3873 17.8945 14.5555 17.8945C14.7237 17.8945 14.8849 17.9614 15.0038 18.0803C15.1228 18.1992 15.1896 18.3605 15.1896 18.5286V20.0758C15.1896 20.244 15.1228 20.4052 15.0038 20.5242C14.8849 20.6431 14.7237 20.7099 14.5555 20.7099Z"
      fill="#475467"
    />
    <path
      d="M12.8264 26.8033C11.5847 26.6145 10.4647 25.9511 9.70288 24.9526C8.94104 23.9542 8.59696 22.6988 8.74274 21.4514C8.71568 21.1222 8.71568 20.7913 8.74274 20.4622C8.74274 20.3713 8.76199 20.2816 8.79971 20.199C8.83743 20.1163 8.8927 20.0427 8.96133 19.9832C9.02997 19.9237 9.11042 19.8794 9.19758 19.8538C9.28473 19.8281 9.37639 19.8217 9.46633 19.8346C9.55627 19.8474 9.6425 19.8793 9.71898 19.9284C9.79547 19.9774 9.86049 20.0421 9.90971 20.1185C9.95893 20.1948 9.99107 20.2809 10.0041 20.3708C10.0172 20.4607 10.011 20.5524 9.98557 20.6396C9.98557 20.8932 9.98557 21.1724 9.98557 21.4514C9.98557 23.7088 11.2538 25.535 12.801 25.535C14.3482 25.535 15.6164 23.7088 15.6164 21.4514C15.6424 21.1815 15.6424 20.9095 15.6164 20.6396C15.594 20.4721 15.6368 20.3024 15.7359 20.1655C15.835 20.0287 15.9828 19.9352 16.1489 19.9042C16.2323 19.8936 16.3169 19.8995 16.3979 19.9219C16.4789 19.9443 16.5547 19.9827 16.6208 20.0346C16.6869 20.0865 16.7421 20.151 16.783 20.2244C16.824 20.2978 16.8498 20.3787 16.8592 20.4622C16.8863 20.7913 16.8863 21.1222 16.8592 21.4514C17.0062 22.6913 16.6687 23.94 15.9173 24.9372C15.166 25.9343 14.0587 26.6028 12.8264 26.8033Z"
      fill="#475467"
    />
    <path
      d="M15.1337 8.08436C14.5371 8.09272 13.9468 7.96242 13.4091 7.70384C12.8618 7.42531 12.3712 7.04692 11.9632 6.58795C11.7086 6.24429 11.3546 5.98734 10.9488 5.85229C10.0864 5.64937 8.91948 6.33423 7.57517 7.78C7.51602 7.84137 7.44524 7.89033 7.36679 7.92367C7.28835 7.95701 7.20402 7.97414 7.11878 7.97414C7.03355 7.97414 6.94922 7.95701 6.87077 7.92367C6.79233 7.89033 6.72124 7.84137 6.66209 7.78C6.5459 7.66147 6.48096 7.50198 6.48096 7.336C6.48096 7.17002 6.5459 7.01084 6.66209 6.89231C8.38686 5.04071 9.88351 4.35581 11.2532 4.60946C11.9057 4.78637 12.4836 5.16896 12.9016 5.70026C13.2053 6.03919 13.5659 6.32235 13.967 6.53717C14.728 6.89227 15.9455 7.12064 18.4566 5.67487C18.5283 5.62268 18.6101 5.58597 18.6969 5.56743C18.7836 5.54889 18.8735 5.54883 18.9603 5.56712C19.0472 5.58542 19.129 5.62165 19.2009 5.67363C19.2728 5.72561 19.3331 5.7923 19.3777 5.869C19.4223 5.94571 19.4502 6.03067 19.4598 6.11887C19.4694 6.20708 19.4605 6.29631 19.4334 6.38081C19.4064 6.46531 19.3618 6.5434 19.3028 6.60962C19.2437 6.67585 19.1715 6.72882 19.0907 6.76537C17.9102 7.54432 16.5455 7.99923 15.1337 8.08436Z"
      fill="#475467"
    />
    <path
      d="M25.0739 35.3492C23.0727 35.3506 21.1329 34.6583 19.5852 33.3899C18.0374 32.1214 16.9772 30.3556 16.5855 28.3932C16.1938 26.4307 16.4948 24.3931 17.437 22.6277C18.3792 20.8622 19.9045 19.4782 21.7528 18.7112C23.6012 17.9443 25.6582 17.842 27.5735 18.4217C29.4888 19.0015 31.1438 20.2275 32.2565 21.8908C33.3692 23.554 33.8706 25.5513 33.6755 27.5429C33.4804 29.5345 32.6011 31.397 31.1868 32.8127C29.566 34.4352 27.3672 35.3475 25.0739 35.3492ZM25.0739 19.319C23.3664 19.3217 21.7128 19.9163 20.3945 21.0015C19.0763 22.0868 18.1748 23.5954 17.8441 25.2706C17.5135 26.9458 17.774 28.6839 18.5811 30.1887C19.3881 31.6934 20.6918 32.8717 22.2702 33.523C23.8486 34.1743 25.6042 34.2583 27.2375 33.7605C28.8708 33.2627 30.2811 32.214 31.2279 30.7931C32.1748 29.3721 32.5996 27.667 32.4302 25.9679C32.2608 24.2688 31.5078 22.6809 30.2991 21.4749C29.6142 20.7876 28.7995 20.243 27.9026 19.8729C27.0057 19.5028 26.0441 19.3147 25.0739 19.319Z"
      fill="#475467"
    />
    <path
      d="M29.7195 27.3317C29.5514 27.3317 29.3901 27.2651 29.2712 27.1462C29.1523 27.0273 29.0854 26.8657 29.0854 26.6976C29.0888 26.1703 28.9875 25.6477 28.7872 25.16C28.587 24.6722 28.2922 24.2293 27.9194 23.8564C27.5466 23.4836 27.1033 23.1885 26.6155 22.9883C26.1278 22.7881 25.6052 22.6867 25.078 22.6901C24.9098 22.6901 24.7485 22.6232 24.6296 22.5043C24.5107 22.3854 24.4438 22.2242 24.4438 22.056C24.4438 21.8878 24.5107 21.7266 24.6296 21.6076C24.7485 21.4887 24.9098 21.4219 25.078 21.4219C26.4772 21.4219 27.8192 21.9778 28.8086 22.9672C29.798 23.9566 30.3536 25.2983 30.3536 26.6976C30.3536 26.8657 30.2871 27.0273 30.1682 27.1462C30.0493 27.2651 29.8877 27.3317 29.7195 27.3317Z"
      fill="#475467"
    />
    <path
      d="M33.2685 35.5256C33.0992 35.5273 32.9359 35.4635 32.8121 35.3479L30.2757 32.8115C30.2143 32.7523 30.1654 32.6816 30.132 32.6031C30.0987 32.5247 30.0815 32.4403 30.0815 32.3551C30.0815 32.2699 30.0987 32.1852 30.132 32.1068C30.1654 32.0283 30.2143 31.9576 30.2757 31.8984C30.3971 31.7879 30.5552 31.7266 30.7194 31.7266C30.8835 31.7266 31.0419 31.7879 31.1634 31.8984L33.6998 34.4348C33.8103 34.5563 33.8713 34.7147 33.8713 34.8788C33.8713 35.043 33.8103 35.2011 33.6998 35.3225C33.5864 35.4416 33.4325 35.5141 33.2685 35.5256Z"
      fill="#475467"
    />
    <path
      d="M43.9445 48.5867C43.472 48.588 43.0175 48.4063 42.6762 48.0796L32.2011 37.5787C32.0292 37.4152 31.8921 37.2186 31.7985 37.0006C31.705 36.7826 31.6567 36.5477 31.6567 36.3104C31.6567 36.0732 31.705 35.8386 31.7985 35.6206C31.8921 35.4026 32.0292 35.2057 32.2011 35.0422L33.4439 33.7994C33.7873 33.4766 34.2408 33.2969 34.7121 33.2969C35.1834 33.2969 35.6369 33.4766 35.9803 33.7994L46.4558 44.3003C46.6277 44.4638 46.7645 44.6603 46.858 44.8784C46.9516 45.0964 46.9998 45.3313 46.9998 45.5685C46.9998 45.8057 46.9516 46.0403 46.858 46.2583C46.7645 46.4764 46.6277 46.6732 46.4558 46.8367L45.2127 48.0796C45.047 48.246 44.8493 48.3769 44.6312 48.4641C44.4131 48.5513 44.1793 48.593 43.9445 48.5867ZM34.6867 34.5855C34.5529 34.5874 34.4252 34.6423 34.3316 34.7379L33.0887 35.9553C33.0383 36.0047 32.9985 36.0641 32.9723 36.1296C32.9461 36.1951 32.9339 36.2653 32.9364 36.3358C32.9383 36.4696 32.9932 36.5973 33.0887 36.691L43.5896 47.1665C43.6348 47.2157 43.6895 47.2548 43.7506 47.2817C43.8118 47.3085 43.8777 47.3225 43.9445 47.3225C44.0112 47.3225 44.0775 47.3085 44.1386 47.2817C44.1997 47.2548 44.2545 47.2157 44.2996 47.1665L45.5424 45.9236C45.5916 45.8785 45.6311 45.8235 45.6579 45.7623C45.6848 45.7012 45.6988 45.6353 45.6988 45.5685C45.6988 45.5017 45.6848 45.4355 45.6579 45.3744C45.6311 45.3132 45.5916 45.2585 45.5424 45.2134L35.0669 34.7379C35.0176 34.6874 34.9584 34.648 34.8929 34.6218C34.8274 34.5956 34.7572 34.583 34.6867 34.5855Z"
      fill="#475467"
    />
  </svg>
);

export default OutsourcingHeadhunting;
